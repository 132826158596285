import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BodyContent from './components/BodyContent';
// import Header from './components/header/Header'
// import Nav from './components/nav/Nav'
import About from './components/About'
// import Experience from './components/experience/Experience'
import Services from './components/Services'
// import Portafolio from './components/portafolio/Portafolio'
// import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/Contact'
import Footer from './components/Footer'
import MainArea from './components/MainArea'

 import "./App.css";
// import Navbar from "./components/Navbar";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from './components/Navigation';


function App() {
  return (
    <Router>
      {/* <Navigation /> */}
      <MainArea />
      <About />
      <Services/>
      <Contact/>
      <Footer />
      {/* <BodyContent/> */}
      <Routes>
        <Route path="/" />
        <Route path="about" />
        <Route path="/services" />
        <Route path="/contact" />
      </Routes>
    </Router>
  );
}

export default App

// const App = () => {
//   return (
//   <>
//   <MainArea/>
//   {/* <Header/>
//   <Nav/>
//   <About/>
//   <Experience/>
//   <Services/>
//   <Portafolio/>
//   <Testimonials/>
//   <Contact/>
//   <Footer/> */}

//   </>
//   )
// }