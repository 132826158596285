import React from 'react'
import styled from 'styled-components'
import { InnerLayout } from '../Layouts';

function BodyContent() {
    return (
        <BodyContentStyled>
            <InnerLayout>

            </InnerLayout>
        </BodyContentStyled>
    )
}

const BodyContentStyled = styled.main`

`;
export default BodyContent