import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavbarContainer = styled.nav`
  width: 100%;
  height: ${(props) => (props.extendNavbar ? "100vh" : "80px")};
  background-color: transparent;
  display: flex;
  flex-direction: column;
 

  @media (min-width: 700px) {
    height: 80px;
  }
`;

export const LeftContainer = styled.div`
  flex: 70%;
  display: flex;
  align-items: center;
  padding-left: 5%;
`;

export const RightContainer = styled.div`
  flex: 30%;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`;

export const NavbarLinkContainer = styled.div`
  display: flex;
`;

export const NavbarLink = styled.ul`
display: flex;
align-items: center;
text-align: center;
;

@media only screen and (max-width:1024px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* height: 100vh; */
    position: relative;
    top: 40px;
    padding-right:25px;
    right: ${({ click }) => click ? '5' : '-75%'};
    /* background-color: rgba(0, 0, 0, 0.9); */
    transition: all .5s ease;
}

  @media (max-width: 700px) {
    display: none;
  }
`;

export const MenuItem = styled.li`
list-style: none;
height: 60px;
padding-left:15px;
margin-left:45px;


@media only screen and (max-width:1024px){
    width: 100%;
    padding-right:90px;
    margin-left:-90px;
    &:hover {
        border: none;
    }
}
`;

export const NavbarLinkExtended = styled(Link)`
  color: white;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const Logo = styled.img`
  margin: 15px;
  max-width: 180px;
  height: auto;
`;

export const OpenLinksButton = styled.button`
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  color: white;
  font-size: 45px;
  cursor: pointer;

  @media (min-width: 700px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 700px) {
    display: none;
  }
`;