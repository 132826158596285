import React from 'react'
import './services.css'
import { BiCheckShield } from 'react-icons/bi'
import MainTitle from './MainTitle'

const Services = () => {
  return (
    <section id="services" className='serviceC'>
      <MainTitle title='Servicios'/>

      <div className="container services__container">

        {/* end of 1st part */}

        <article className="services">
          <div className="services__head">
            <h3>Auditoría interna</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Programas de calidad para <br/>auditoría interna</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Auditorías internas basadas en el riesgo</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Diferentes servicios de auditoría</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Manuales de políticas para la<br/>gestión de auditoría interna</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Capacitaciones y diplomados</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Análisis de datos enfocado<br/>en la auditoría interna</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Servicios de tercerización <br/>de auditoría interna</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Otros servicios de asesoría y consultoria</p>
            </li>


          </ul>
        </article>

        <article className="services">
          <div className="services__head">
            <h3>Fraude</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Investigación financiera</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Contabilidad forense</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Auditoría forense</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Examenes de fraude institucional</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Investigación de fraudes</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Evaluación de control interno</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Análisis y fraude</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Capacitaciones</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Políticas, procesos, procedimientos,<br/>programas anti-fraudes</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Programas y estrategias anticorrupción</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Evaluaciones de riesgo</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Tercerización auditorías antifraudes</p>
            </li>

          </ul>
        </article>


        <article className="services">
          <div className="services__head">
            <h3>Administración de riesgos</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Elaboración de manuales de gestión<br/>integral de riesgos</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Talleres de autoevaluación <br/>riesgos-controles</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Auditoría interna especializada<br/>en administración de riesgos</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Análisis de datos</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Diseño de estructuración,<br/>apetitos al riesgo,tolerancias<br/>al riesgo y cultura del riesgo</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Capacitaciones y diplomados</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Evalauciones al riesgo por eventos<br/>y procesos</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Diseño KRI y KPI</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Otras asesorías y consultorías</p>
            </li>


          </ul>
        </article>




        {/* end 2nd part */}




        {/* end 3rd part */}



        {/* end 4th part */}

        <article className="services">
          <div className="services__head">
            <h3>Finanzas y estrategias</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Análisis y evalaucion de riesgos<br/>estrátegicos y financieros</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Asesoramiento en la formulación<br/>de estrategias</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Consideración del riesgo en<br/>la formulación de estrategias</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Otras asesorías y consultorías</p>
            </li>


          </ul>
        </article>

        <article className="services">
          <div className="services__head">
            <h3>Lavado de activos</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Evaluaciones de riesgo</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Evaluaciones independientes al <br/>programa de cumplimiento<br/>y estrategias de lavado</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Enfoque basado en el riesgo</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Auditorías especializadas en lavado<br /> de activos y financiamiento terrorismo</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Capacitaciones</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Diseño de programas de cumplimiento</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Analitic data en lavado de activos</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Otras asesorías y consultorías</p>
            </li>


          </ul>
        </article>

        <article className="services">
          <div className="services__head">
            <h3>Cumplimiento Normativo</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Auditorías especializadas</p>

            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Evaluaciones de riesgos</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Políticas, procesos, procedimientos,<br/> programas</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Capacitaciones</p>
            </li>
            <li>
              <BiCheckShield className='service__list-icon' />
              <p>Otras asesorías y consultorías<br/>en complience</p>
            </li>

          </ul>
        </article>


        {/* end 5th part */}




        {/* end 6th part */}
      </div>
    </section>
  )
}

export default Services