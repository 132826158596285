import React, { useState } from "react";
import {
    NavbarContainer,
    LeftContainer,
    RightContainer,
    NavbarExtendedContainer,
    NavbarInnerContainer,
    NavbarLinkContainer,
    NavbarLink,
    Logo,
    OpenLinksButton,
    NavbarLinkExtended,
    MenuItem
} from "../styles/Navbar.style";
// import styled from 'styled-components';
import logo from '../assets/info10.png';

function Navigation() {
    const [extendNavbar, setExtendNavbar] = useState(false);
    const [activeNav, setActiveNav] = useState('#')
    return (
        <NavbarContainer extendNavbar={extendNavbar}>
            <NavbarInnerContainer>
                <LeftContainer>
                    <Logo src={logo}></Logo>
                </LeftContainer>
                <RightContainer>
                    <NavbarLinkContainer>
                        <NavbarLink>
                            <MenuItem><a href="#">Inicio</a></MenuItem>
                            <MenuItem><a href="#about">¿Quienes Somos?</a></MenuItem>
                            <MenuItem><a href="#services">Servicios</a></MenuItem>
                            <MenuItem><a href="#contact">Contáctanos</a></MenuItem>
                        </NavbarLink>
                        <OpenLinksButton
                            onClick={() => {
                                setExtendNavbar((curr) => !curr);
                            }}
                        >
                            {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
                        </OpenLinksButton>

                    </NavbarLinkContainer>
                </RightContainer>

            </NavbarInnerContainer>
            {extendNavbar && (
                <NavbarExtendedContainer>
                    {/* <NavbarLinkExtended to="/"><a href="#">Inicio</a></NavbarLinkExtended> */}
                    <br></br>
                    <a href="#">Inicio</a>
                    <br></br>
                    <a href="#about">¿Quienes Somos?</a>
                    <br></br>
                    <a href="#services">Servicios</a>
                    <br></br>
                    <a href="#contact">Contáctanos</a>
                    {/* <NavbarLinkExtended to="/services">Servicios</NavbarLinkExtended>
                    <NavbarLinkExtended to="/contact">Contáctanos</NavbarLinkExtended> */}
                </NavbarExtendedContainer>
            )}
        </NavbarContainer>


    )
}

// const NavigationStyled = styled.nav`
//     min-height: 10vh;
//     display: flex;
//     justify-content: space-right;
//     align-items: center;
//     // margin-left:15rem;
//     .nav-items{
//         display: flex;
//         align-items: center;
//         li{
//             margin: 0 1rem;
//             // left:-25%;
//         }
//         .primary-btn{
//             // margin-left: 25px;
//             background-color:rgba(57, 95, 246, 0.6);
//             padding: .6rem 0.9rem;
//             border-radius: 70px;
//             cursor: pointer;
//             transition: all .4s ease-in-out;
//             &:hover{
//                 background-color:rgba(57, 95, 246, 1);
//             }
//         }
//     }

//     .logo{
//         // margin-left:35rem;
//         img{
//             width: 60px;
//             margin-right:45rem;

//         }
//     }
// `;

export default Navigation;


{/* <NavigationStyled>
          
          <div className="logo">
              <img src={logo} alt="" />
          </div>
          <ul className="nav-items">
              <li>
                  <a href="#">Inicio</a>
              </li>
              <li className='k'>
                  <a href="#">¿Quienes somos?</a>
              </li>
              <li>
                  <a href="#">Servicios</a>
              </li>
             
              <li>
                  <a href="#">Contáctanos</a>
              </li>
              <div className="primary-btn">GET CONNECTED</div>
          </ul>
      </NavigationStyled> */}