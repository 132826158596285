import React from 'react'
import './footer.css'
import { BsLinkedin } from 'react-icons/bs'
import loguito from '../assets/info10.png'

const Footer = () => {
  return (
    <footer className='fondoF'>
    

      <a href="#" className='footer__logo'>Raaf Consulting Center</a>

      <ul className='permalinks'>
        <a href="#">Inicio</a>
        <a href="/#about">¿Quienes somos?</a>
        <a href="#services"> Servicios</a>
        <a href="#contact">Contáctanos</a>
      </ul>

      <div className="footer__sociales">
        {/* Visita también: */}
        <a href="https://www.linkedin.com/in/juda-aaron-ponce-cfe-cams-ncr-master-in-finance-5169a8120"><BsLinkedin /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Raaf Consulting Center. Todos los derechos reservados.</small>
        <br></br>
        <small>Tegucigalpa, Honduras</small>
      </div>
    </footer>
  )
}

export default Footer