import styled from 'styled-components'

export default function Input({type, placeholder, name, required}){

    return (
        <StyledInput type={type} name={name} placeholder={placeholder} required={required}/>

    )
}

const StyledInput =styled.input`
    background: white;
    /* box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37); */
    border-radius:2rem;
    width:80%;
    height:3rem;
    padding:1rem;
    border:none;
    outline:none;
    color: #3c354e;
    font-size:0.8rem;
    font-weight:bold;
    margin-left:-58%;
    @media screen and (max-width:600px){
        width:64%;
        margin-left:-60%;

    }
    &:focus{
        display:inline-block;
        box-shadow: 0 0 0 0.2rem #b9abe0;
        backdrop-filter:blur(12rem);
        border-radius:2rem;
    }

    &:placeholder{
        color:#b9abe099;
        font-weight:100;
        font-size:1rem;
}
`;