import React from 'react'
import './contact.css'
import { MdOutlineMailOutline } from 'react-icons/md'
import { BsWhatsapp } from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'
import MainTitle from './MainTitle';
import styled from 'styled-components'
import Input from './Input';
import Button from './Button';

import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mpu5zb6', 'template_codhu0j', form.current, 'igMDg4M9Iz5Ctj82v')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
    alert('Tu mensaje ha sido envíado, pronto nos comunicaremos.')
  };

  return (
    <section id="contact" className='fondoC'>
      <MainTitle title={'Ponte en contacto con nosotros'} />
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMailOutline className='contact__option-icon' />
            <h4 className='h44'>Correo electronico</h4>
            <h5>gerencia@raafconsulting.com</h5>
            <a href="mailto:gerencia@raafconsulting.com" className='mee'>Envía tu mensaje</a>
          </article>

          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon' />
            <h4 className='h44'>WhatsApp</h4>
            <h5>+504 8766-9866</h5>
            {/* https://api.whatsapp.com/send/?phone=50498430706 */}
            {/* https://wa.me/15551234567 */}

            <a href="https://api.whatsapp.com/send?phone=50487669866&https://web.whatsapp.com/send?phone=50487669866&text=Hola, vengo de su sitio web, tengo una consulta.&app_absent=0" className='mee'>Envía tu mensaje</a>
            <a href="https://web.whatsapp.com/send?phone=50487669866&text=Hola, vengo de su sitio web, tengo una consulta." className='mee'></a>

          </article>

        </div>
        <ContactStyled>
          <WelcomeText>
            Escribe tus datos
          </WelcomeText>
          <form ref={form} onSubmit={sendEmail}>
            <InputContainer>
              <Input type="text" name="name" placeholder="Nombre completo" required />
              <br></br>
              <Input type="text" name="business" placeholder="Nombre de la empresa" required />
              <br></br>
              <Input type="email" name="email" placeholder="Dirección de correo" required />
              <br></br>
              <Input type="tel" name="phone" placeholder="Número teléfonico" required />
              <br></br>
            </InputContainer>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <TextStyled>
              <textarea name="message" rows="7" placeholder='Escribe tu consulta' required></textarea>
            </TextStyled>

            <ButtonContainer>
              <StyledButton>
                Enviar
                <button type='submit' className='btn btn-primary'><br></br></button>
              </StyledButton>
            </ButtonContainer>
          </form>

        </ContactStyled>
      </div>



    </section>
  )
}

const ContactStyled = styled.div`
  display:flex;
  align-items:center;
  flex-direction:column;
  height:80vh;
  width:30vw;
  background: rgba(200,200,200,0.15);
  box-shadow: 08px 32px 0 rgba(31,38,135,0.37);
  backdrop-filter:blur(10.5px);
  margin-left:10%;
  border-radius:10px;
  color:#ffffff;
  text-transform:uppercase;
  letter-spacing:0.3rem;
  @media screen and (max-width:1024px){
    align-items:center;
    height:70vh;
    width:85vw;
    margin-left:10%;
    backdrop-filter:blur(8.5px);
}
  @media screen and (max-width:600px){
    align-items:center;
    height:70vh;
    width:85vw;
    margin-left:10%;
    backdrop-filter:blur(8.5px);
    margin-left:-1%;
   
}

`;

const WelcomeText = styled.h2`
  margin:3rem 0 2rem 0;
  @media screen and (max-width:600px){
    font-size:large;
}
`;

const InputContainer = styled.div`
  margin-top:10%;
  display:flex;
  flex-direction: column;

  align-items:center;
  height:20%;
  width:250%;

`;

const TextStyled = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:center;
  height:20%;
  width:180%;
  margin-left:-35%;
  @media screen and (max-width:600px){
    align-items:center;
    height:20%;
    width:160%;
    margin-left:-30%;
}

`;

const ButtonContainer = styled.div`
  margin:1rem 0 2rem 0;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
`;

const StyledButton = styled.button`
   background: linear-gradient(to right, #14163c 0%, #03217b 79%);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  width: 65%;
  height: 3rem;
  border: none;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
`;

export default Contact



