import React, { useState } from 'react'
import './about.css'
// import logo from '../../assets/logoblanco.png'
import { FaAward } from 'react-icons/fa'
import { GiEyestalk } from 'react-icons/gi'
import { GrBusinessService } from 'react-icons/gr'
import { motion } from 'framer-motion'
import MainTitle from './MainTitle'
import { BiCheckShield } from 'react-icons/bi'
import { faL } from '@fortawesome/free-solid-svg-icons'
import { MdOutlineBusiness } from 'react-icons/md'
import { BsGraphUp } from 'react-icons/bs'




const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);


  return (
    <section id="about" className='fondoA'>

      <MainTitle title={'Conócenos'} />
      <div className="container services__container1">

        <motion.div layout onClick={() => {
          setIsOpen(!isOpen)
          setIsOpen2(false)
          setIsOpen3(false)
        }} className='card'>

          <motion.h2 layout className='subtii'> ¿Quienes Somos? <MdOutlineBusiness /></motion.h2>
          {isOpen &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}

            >
              <p className='letra'>
                Somos una empresa especializada en asesoría, consultoría y capacitación en diferentes disciplinas relacionadas a auditoría interna, finanzas, fraudes, lavado de activos, cumplimiento normativo, administración de riesgos y estrategias, encaminada a apoyar el sector empresarial,  instituciones financieras, microfinancieras, cooperativas, comerciales, industriales y servicios,
                ofreciendo servicios especializados que permita adaptarse al cambio del entorno externo.
              </p>
            </motion.div>}
        </motion.div>


        <motion.div layout onClick={() => {
          setIsOpen2(!isOpen2)
          setIsOpen(false)
          setIsOpen3(false)
        }} className='card'>

          <motion.h2 layout className='subtii'> Misión <FaAward /></motion.h2>
          {isOpen2 &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}

            >
              <p className='letra'>
                Contribuir al desarrollo empresarial del país y la región mediante mediante la generación de servicios que permita a las organización transformarse de acuerdo a las exigencias del entorno y entender exigencias regulatorias.
              </p>
            </motion.div>}
        </motion.div>



        <motion.div layout onClick={() => {
          setIsOpen3(!isOpen3)
          setIsOpen2(false)
          setIsOpen(false)
        }} className='card'>

          <motion.h2 layout className='subtii'> Visión <BsGraphUp /></motion.h2>
          {isOpen3 &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}

            >
              <p className='letra'>
                Desarrollar asesorías y consultoria con un enfoque polivalente para atender las necesidades del sector empresarial del país y la Regional fomentando el desarrollo de las actividades en acuerdo a los mejores prácticas y
                estándares internacionales que facilite a las organizaciones atender sus objetivos estratégicos y de negocios
              </p>
            </motion.div>

          }
        </motion.div>

      </div>

    </section>
  )
}

export default About
