import React, { useState } from 'react'
import styled from 'styled-components';
import CtaButton from './CtaButton';
import Navigation from './Navigation';
import SmallHeading from './SmallHeading';
import blockchain from '../assets/fondo66.png';
import { motion, Variants } from "framer-motion";
import { list } from '../components/data1'

const imageAnimate = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
        x: 0,
        opacity: 1,
        rotate: [0, 10, 0],
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1
        }
    }

}

const textAnimate = {
    offscreen: { y: 100, opacity: 0 },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1
        }
    }

}

function MainContent() {

    const [selectedId, setSelectedId] = useState(null)

    function Card({ image, h2, p, id }) {
        return (
            <motion.div className="card" id={id}
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
            >
                <motion.div className="image-container"
                    variants={imageAnimate}
                >{image}</motion.div>
                <motion.h2
                    variants={textAnimate}
                >{h2}</motion.h2>
                <motion.p
                    variants={textAnimate}
                >{p}</motion.p>
            </motion.div>
        );
    }

    function Cartita() {
        return list.map((item, index) => (
            <div className='card-wrapper' key={index}>
                <Card image={item.image} h2={item.h2} p={item.p} />
            </div>
        ))
    }

    return (
        <MainContentStyled >
            <Navigation />
            <div className="content">
                <div className="left">

                    <h1 className='titi'>
                        RAAF Consulting Center
                    </h1>
                    <h3>
                        Consultorías | Aserorías | Capacitaciones
                    </h3>
                    <SmallHeading className="subtiti" title={'Una visión polivalente de la consultoría empresarial'} identifier={'Before'} />
                    <div className="btns-con">
                        <a href='#about'>
                            <CtaButton name={'Iniciemos'} />
                        </a>
                        {/* <CtaButton name={'get help'} /> */}
                    </div>

                </div>
              

                <div className="right">
                    <img src={blockchain} alt="" />
                </div>
                <Cartita />



            </div>
        </MainContentStyled >
    )
}


const MainContentStyled = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 100%;

    .card{
        height:80%;
        width:60%;
        border-radius:13px;
        box-shadow:20px 40px 33px rgba(0,0,0,0.3);
        padding:1rem;
        background-color:#75b2c5;
        margin-top:-25%;
        margin-right:-100%;
      
    }
    .card-wrapper{
        min-height:35vh;
        display:flex;
        align-items:center;
        justify-content:space-around;
        background-color: transparent;
        color:white;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
    }

    .card h2{
        font-size:3vh;
        text-align:center;
        
    }

    .card p{
        font-size:2vh;
    }

    .image-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 20vh;
  background-image: linear-gradient(70deg, RoyalBlue, DarkTurquoise);
  box-shadow: 10px 15px 15px 6px #3891b4;
  border-radius: 15px;
  font-size: 15vh;
}
    .content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 100%;
        width: 100%;
        .left{
            display: flex;
            justify-content: center;
            flex-direction: column;
            h1{
                padding: 1.8rem 0;
            }
            .btns-con{
                margin-top: 3rem;
            }
        }

        .right{
            img{
                position: absolute;
                right: -30%;
                bottom: 35%;
                width: 60%;
                /* top:5%; */
                border-radius:25%;
                height:60%;
            }
        }
    }

    @media  screen and (max-width:1024px){
        position: absolute;
    /* top: 10; */
    left: 33%;
    transform: translateX(-50%);
    width: 80%;
    height: 100%;
    align-content:center;

        .content{
            display:grid;
            grid-template-columns: 1fr;
            text-align:center;

            .titi{
                text-align:center;
            }
       
            .left{
                display: flex;
                text-align:center;
                flex-direction: column;
                h1{
                    padding: 1.8rem 0;
                    text-align:center;
                    margin-left:39%;
            
                }

                h3{
              
                    text-align:center;
                    font-size:large;
                    margin-left:38%;
            
                }
                .btns-con{
                    margin-top: 5rem;
                    margin-left:29%;
                }
            }

            .right{
                img{
                    position: absolute;
                    right: -7%;
                    bottom: 10%;
                    width: 60%;
                /* top:5%; */
                    border-radius:25%;
                }
            }
        }

    }

    @media screen and (max-width:600px){
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 100%;

        .card{
        height:25%;
        width:80%;
        border-radius:13px;
        box-shadow:20px 40px 33px rgba(0,0,0,0.3);
        padding:1rem;
        background-color:#75b2c5;
        margin-bottom:75%;
        margin-right:1%;
    }
    .card-wrapper{
        height:25%;
        display:flex;
        align-items:center;
        justify-content:space-around;
        background-color: transparent;
        color:white;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
    }

    .card h2{
        font-size:2vh;
        text-align:center;
        
    }

    .card p{
        font-size:2vh;
    }
    h1{
        margin-top:10%;
    }

    .subtiti{
        margin-top:-10%;
    }

        .content{
            grid-template-columns: 1fr;
       
            .left{
                display: flex;
                justify-content: center;
                flex-direction: column;
                h1{
                    padding: 0.8rem 1;
                    text-align:center;
                    margin-left:-9%;
                }

                h3{
                    font-size:small;
                    text-align:center;
                    margin-left:-2%;
                }
                .btns-con{
                    margin-top:2rem;
                    margin-left:-10%;
                    
                    margin-bottom:25%;
                  
                }
            }

            .right{
                img{
                    position: absolute;
                    right: -105%;
                    bottom: 2%;
                    width: 60%;
                /* top:5%; */
                    border-radius:25%;
                }
            }
        }
    }
`;
export default MainContent;